<template>
  <div>
    <b-container class="h-16 flex items-center">
      <img src="@/assets/logo.svg" alt="LOGO" class="h-full py-1">
    </b-container>

    <b-landing @enquiry-click="scrollIntoViewWithOffset(40)"/>

    <div class="bg-zinc-900 text-white">
      <b-container class="py-10 grid md:grid-cols-2">
        <div class="md:pr-5 md:flex md:flex-col md:justify-center">
          <div class="text-3xl font-semibold mb-7">Bathroom Renovator</div>
          <div class="mb-7">
            Have one person to deal with from start to finish. We have it all covered. Tiling, Plumbing, PVC, Panelling, flooring and we even remove the rubbish.
            <br>
            <br>
            See into the future.  Our interior design team can help you decide what you would like your new bathroom sanctuary to look like, before you have paid the price!
          </div>
        </div>
        <div>
          <img
            src="@/assets/3d-example.jpeg"
            alt="3D Mock example"
            class="rounded-md mb-2"
            loading="lazy"
          />
          <div class="text-center">3D Virtual Design</div>
        </div>
      </b-container>
    </div>

    <b-container class="py-10 text-zinc-900 grid md:grid-cols-2">
      <div class="md:pr-5 md:flex md:flex-col md:justify-center">
        <div class="text-3xl font-semibold mb-7">Flexible</div>
        <div>
          We know you will change your mind and thats ok.  We will alter anything at last minute so don't be afraid to say. 
          <br>
          <br>
          As a family run business we know hours through the day can be tricky, you tell us when to work and we'll try to accommodate as best we can.
        </div>
      </div>
      <div class="md:flex md:items-center mt-7">
        <img src="@/assets/logo.svg" alt="LOGO" class="h-full">
      </div>
    </b-container>
    
    <div class="white--wall--image bg-cover bg-center">
      <div class="bg-white/80 w-full h-full py-10">
        <b-container class="text-zinc-900">
          <div class="text-right text-5xl antialiased">
            Bathroom Bliss
          </div>
          <b-gallery />
        </b-container>
      </div>
    </div>

    <div class="bg-zinc-900 text-white">
      <b-container class="py-10  grid md:grid-cols-2">
        <div class="md:pr-5">
          <div class="text-3xl font-semibold mb-7">Kitchen Tiling</div>
          <div>
            We can tile anywhere so if you fancy revamping your kitchen call us today! Just have a look at what we can do. 
          </div>
        </div>
      </b-container>
    </div>

    <div class="white--wall--image bg-cover bg-center">
      <div class="bg-white/80 w-full h-full py-10">
        <b-container class="text-zinc-900">
          <div class="text-right text-5xl antialiased">
            Splash back
          </div>
          <div class="grid md:grid-cols-2 md:gap-4">
            <div class="pt-4">
              <img class="w-full rounded" src="@/assets/mbs/tiles-1.jpg" />
            </div>
            <div class="pt-4">
              <img class="w-full rounded" src="@/assets/mbs/tiles-2.jpg" />
            </div>
            <div class="pt-4 md:pt-0 md:col-span-2">
              <img class="w-full rounded" src="@/assets/mbs/tiles-3.jpg" />
            </div>
          </div>
        </b-container>
      </div>
    </div>

    <div class="bg-zinc-900 text-white py-10 ">
      <b-container class="grid sm:grid-cols-2 gap-4">
        <div class="bg-blue-500 p-4 rounded-md h-full sm:mb-0 flex flex-col">
          <div class="text-2xl mb-5 font-bold">Facebook</div>
          <div class="text-md">
            Message us, leave a comment or share your recent renovation! We're
            always ready to hear what you have to say.
          </div>
          <div class="bg-white/20 hover:bg-white/30 transition font-bold w-min px-6 py-2 rounded-md mt-5 text-white cursor-pointer">
            View
          </div>
        </div>

        <div class="bg-gradient-to-br from-indigo-600 to-pink-600 p-4 rounded-md flex flex-col">
          <div class="text-2xl mb-5 font-bold">Instagram</div>
          <div class="text-md">
            Where we upload our recent renovations with before and after photos. It might just convince you!
          </div>
          <div class="bg-white/20 hover:bg-white/30 transition font-bold w-min px-6 py-2 rounded-md sm:mt-auto mt-5 text-white cursor-pointer">
            View
          </div>
        </div>
      </b-container>
    </div>

    <div class="white--wall--image bg-cover bg-center">
      <div class="bg-white/80 w-full h-full py-10">
        <b-container class="text-zinc-900">
          <div class="text-right text-5xl antialiased">
            Herring Bone
          </div>
          <div class="columns-2">
            <div class="pt-4">
              <img class="w-full rounded" src="@/assets/mbs/herring-1.jpeg" />
            </div>
            
            <div class="pt-4">
              <img class="w-full rounded" src="@/assets/mbs/herring-3.jpeg" />
            </div>
            <div class="pt-4">
              <img class="w-full rounded" src="@/assets/mbs/herring-2.jpeg" />
            </div>
            <div class="pt-4">
              <img class="w-full rounded" src="@/assets/mbs/herring-4.jpeg" />
            </div>
          </div>
        </b-container>
      </div>
    </div>

    <b-container class="py-10">
      <div class="text-xl mb-4" ref="enquiry">
        Make a quick enquiry
      </div>
      <div class="grid md:grid-cols-2 gap-4">
        <div>
          <div class="flex flex-col mb-2">
            <span class="mb-1">Your name:</span>
            <input type="text" class="bg-zinc-100 border rounded-md p-2 focus:border-emerald-600 focus:outline-none" placeholder=""  v-model="$v.form.name.$model">
            <div class="text-xs text-zinc-500 flex justify-between mt-1" v-if="$v.form.name.$error">
              <div>
                <span class="text-red-600" v-if="!$v.form.name.required">You need to enter your name.</span>
                <span class="text-red-600" v-else-if="!$v.form.name.minLength">Your name must be atleast 4 letters.</span>
                <span class="text-red-600" v-else-if="!$v.form.name.maxLength">Your name must not be more than 50 letters.</span>
              </div>
              <div class="ml-auto">{{ nameLength }}/50</div>
            </div>
            <div class="h-[20px]" v-else />
          </div>
          <div class="flex flex-col mb-2">
            <span class="mb-1">Your phone number:</span>
            <input type="text" class="bg-zinc-100 border rounded-md p-2 focus:border-emerald-600 focus:outline-none" v-model="$v.form.phone.$model">
            <div class="text-xs text-zinc-500 flex justify-between mt-1" v-if="$v.form.phone.$error">
              <div>
                <span class="text-red-600" v-if="!$v.form.phone.required">You need to enter a phone number.</span>
                <span class="text-red-600" v-else-if="!$v.form.phone.valid">Please enter a valid phone number.</span>
                <span class="text-red-600" v-else-if="!$v.form.phone.maxLength">Your number must not be more than 20 digits.</span>
              </div>
              <div class="ml-auto">{{ phoneLength }}/20</div>
            </div>
            <div class="h-[20px]" v-else />
          </div>
          <div class="flex flex-col mb-2">
            <span class="mb-1">Your email address:</span>
            <input type="text" class="bg-zinc-100 border rounded-md p-2 focus:border-emerald-600 focus:outline-none" v-model="$v.form.email.$model">
            <div class="text-xs text-zinc-500 flex justify-between mt-1" v-if="$v.form.email.$error">
              <div>
                <span class="text-red-600" v-if="!$v.form.email.required">You need to enter an email address.</span>
                <span class="text-red-600" v-else-if="!$v.form.email.email">Please enter a valid email address.</span>
              </div>
            </div>
            <div class="h-[20px]" v-else />
          </div>
        </div>
        <div class="mt-[24px] hidden md:block">
          If you want to improve the bathroom you have or a full bathroom renovation, contact us for a free call out & estimate.
          No job too small!
        </div>
        <div class="flex flex-col mb-4 col-span-2">
          <span class="mb-1">Message</span>
          <textarea type="text" class="bg-zinc-100 border rounded-md p-2 focus:border-emerald-600 focus:outline-none" v-model="$v.form.message.$model" />
          <div class="text-xs text-zinc-500 flex justify-between mt-1" v-if="$v.form.message.$error">
            <div>
              <span class="text-red-600" v-if="!$v.form.message.required">You need to enter a message for us.</span>
              <span class="text-red-600" v-else-if="!$v.form.message.minLength">Your message must be atleast 20 letters.</span>
              <span class="text-red-600" v-else-if="!$v.form.message.maxLength">Your name must not be more than 500 letters.</span>
            </div>
            <div class="ml-auto">{{ messageLength }}/500</div>
          </div>
          <div class="h-[20px]" v-else />
        </div>
        
      </div>
      <div class="flex items-center">
        <vue-recaptcha 
          ref="invisibleRecaptcha"
          @verify="onVerify"
          @expired="onExpired"
          size="invisible"
          :sitekey="sitekey"
        ></vue-recaptcha>
        <button class="bg-emerald-600 w-min px-12 rounded-lg py-3 text-white font-bold hover:bg-emerald-500 transition cursor-pointer" @click="submitForm">
          <loading-icon class="h-10"></loading-icon>
          Submit
        </button>
        <transition 
          enter-active-class="animate__animated animate__fadeIn" 
          leave-active-class="animate__animated animate__fadeOut"
        >
          <div class="text-emerald-600 text-sm ml-6" v-if="showSuccessMessage">
            We got your message, Thank you!
          </div>
        </transition>
        <transition 
          enter-active-class="animate__animated animate__fadeIn" 
          leave-active-class="animate__animated animate__fadeOut"
        >
          <div class="text-red-600 text-sm ml-6" v-if="showErrorMessage">
            We had difficulties receiving your message. Try again...
          </div>
        </transition>
        <transition 
          enter-active-class="animate__animated animate__fadeIn" 
          leave-active-class="animate__animated animate__fadeOut"
        >
          <div class="text-red-600 text-sm ml-6" v-if="showValidationError">
            Please provide valid contact information and try again...
          </div>
        </transition>
      </div>

      <div class="mt-5 md:hidden">
        When it comes to updating your bathroom suite we make it an easy journey for you.
        If you want to improve the bathroom you have or a full bathroom renovation, contact us for a free call out & estimate.
        No job too small!
      </div>
    </b-container>

    <b-container class="py-10 text-zinc-900 grid md:grid-cols-2 gap-4">
      <div class="md:col-span-2 text-center">
        <p>Contact</p>
        <p>Jonathan or Julie-Anne</p>
      </div>
      <div class="bg-zinc-100 rounded-md">
        <div class="flex justify-center my-7">
          <img class="h-24" src="@/assets/icons/envelope.svg" alt="Envelope">
        </div>
        <div class="text-center flex flex-col mb-4">
          <span class="text-4xl font-bold mb-4">Email</span>
          <span class="mb-4">We will reply as soon as possible</span>
          <span class="text-emerald-600 underline">modernbathroomsolutions@gmail.com</span>
        </div>
      </div>
      <div class="bg-zinc-100 rounded-md">
        <div class="flex justify-center my-7">
          <img class="h-24" src="@/assets/icons/phone-call.svg" alt="Envelope">
        </div>
        <div class="text-center flex flex-col mb-4">
          <span class="text-4xl font-bold mb-4">Phone</span>
          <span class="mb-4">Mon - Sat 09:00 - 17:00</span>
          <span class="text-emerald-600 underline">(+44) 7470 830 886</span>
        </div>
      </div>
    </b-container>

    <div class="bg-zinc-900 text-white py-10">
      <b-container class="flex flex-col justify-evenly">
        <div class="pb-1 cursor-pointer">Facebook</div>
        <div class="cursor-pointer">Instagram</div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import { enquiry } from '@/api';
import { required, minLength, maxLength, helpers, email } from 'vuelidate/lib/validators';

export default {
  name: 'app',

  components: {
    VueRecaptcha
  },

  validations: {
    form: {
      name: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(50)
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        valid: (value) => !helpers.req(value) || /^\(?(?:(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?\(?(?:0\)?[\s-]?\(?)?|0)(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}|\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4}|\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3})|\d{5}\)?[\s-]?\d{4,5}|8(?:00[\s-]?11[\s-]?11|45[\s-]?46[\s-]?4\d))(?:(?:[\s-]?(?:x|ext\.?\s?|)\d+)?)$/.test(value),
        maxLength: maxLength(20)
      },
      message: {
        required,
        minLength: minLength(20),
        maxLength: maxLength(500)
      }
    }
  },

  data: () => ({
    form: {
      name: '',
      phone: '',
      email: '',
      message: '',
      captcha_token: ''
    },
    showSuccessMessage: false,
    showErrorMessage: false,
    showValidationError: false,

    regex: {
      phone: '',
      email: ''
    },

    sitekey: '6LeRKm8eAAAAAKYmCXmZNKWpTCNNnVggYUxyzBuA',
    verified: false,
  }),

  methods: {
    scrollIntoViewWithOffset (offset) {
      const el = this.$refs.enquiry;
      window.scrollTo({
        behavior: 'smooth',
        top: el.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset
      });
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.form.$invalid) {
        this.createValidationError();
      } else {
        console.log('captcha')
        this.$refs.invisibleRecaptcha.execute();
      }
    },
    sendEnquiry () {
      enquiry.store(this.form).then(response => {
        console.log('Sent enquiry', response);
        this.resetForm();
        this.createSuccessMessage();
      }).catch(error => {
        console.error('Error sending enquiry', error);
        this.createErrorMessage();
      }).finally(() => {
        this.form.captcha_token = '';
      });
    },
    resetForm() {
      this.form.name = '';
      this.form.phone = '';
      this.form.email = '';
      this.form.message = '';
      this.form.captcha_token = '';
      this.$v.$reset();
    },
    createSuccessMessage() {
      this.showSuccessMessage = true;
      setTimeout(() => {
        this.showSuccessMessage = false;
      }, 4000);
    },
    createErrorMessage() {
      this.showErrorMessage = true;
      setTimeout(() => {
        this.showErrorMessage = false;
      }, 4000);
    },
    createValidationError() {
      this.showValidationError = true;
      setTimeout(() => {
        this.showValidationError = false;
      }, 4000);
    },
    onVerify(token) {
      console.log('verified', token);
      this.form.captcha_token = token;
      this.sendEnquiry();
      this.$refs.invisibleRecaptcha.reset();
    },
    onExpired() {
      console.log('expired');
      this.form.captcha_token = '';
    }
  },

  computed: {
    messageLength() {
      return this.form.message.length;
    },
    nameLength() {
      return this.form.name.length;
    },
    phoneLength() {
      return this.form.phone.length;
    }
  }
};
</script>

<style scoped>
.white--wall--image {
  background-image: url('../assets/white-wall.jpg');
}
</style>
