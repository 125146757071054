import { axiosDefault } from '@/config/axios-config';

const ENQUIRY = '/api/enquiry/';

export const store = ({ name, phone, email, message, captcha_token }) => {
  return axiosDefault.post(ENQUIRY, {
    name,
    phone,
    email,
    message,
    captcha_token
  });
};
